<template>
  <div class="item" :style="itemStyle">
    <img class="icon" :src="icon" v-if="icon"/>
    <div class="title">{{title}}</div>
    <div class="value">{{value}}</div>
  </div>
</template>

<script>
  export default {
    props: ["icon", "title", "value", 'bgColor', 'color'],
    data() {
      return {
        itemStyle: ''
      }
    },
    mounted() {
      // this.itemStyle = {
      //   color: this.color,
      //   background: this.bgColor
      // }
    }
  }
</script>

<style scoped>
  .item {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: #f5f9fa;
    border: 1px solid #F0F0F0;
    width: 24%;
    padding: 15px;
  }

  .icon {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 8px;

  }

  .title {
    margin-right: 20px;
    font-size: 14px;
    color: #333333;
    flex: 1;
  }

  .value {
    font-size: 28px;
    color: #2FBB60;
  }

</style>
